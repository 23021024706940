import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { navigate } from "gatsby"

import { Section } from "../../components/sections/sections.js"
import {
  SectionTitle,
  AsideTitle,
  AsideCaption,
  AsideDescription,
} from "../../components/typography.js"

import { CheckedRight, Absent } from "../../components/elements/checkmark.js"

const Button = styled.button`
  display: block;

  height: 2.5em;
  line-height: 0.5em;
  background-color: #f50057;
  color: white;
  outline: none;
  margin: 0 auto;
  padding: 0 20px;
  border: none;
  cursor: pointer;
  transform: scale(0.8);
  font-size: 16px;
  font-weight: 500;
  border-radius: ${props => (props.round ? "30px" : "0px")};
  &:after {
    content: "";
    display: inline-block;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    height: 0.4em;
    width: 0.4em;
    transform: rotate(-45deg);
    margin-left: 0.5em;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 95%;
  justify-content: space-around;
  margin: 0 auto;
`
const Options = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  line-height: 2.3rem;
`
const Descrip = styled.div`
  margin-left: 1em;
  align-items: center;
`
const TagContainer = styled.div`
  width 100%;
`
const Tagline = styled.div`
  border-radius: 50px;
  line-height: 2em;
  background-color: ${props => props.background};
  color: ${props => props.color};
  margin: 0 0 0 auto;
  max-width: 140px;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  transform: scale(0.8);
`
const TitleSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: white;
  position: relative;
  top: -2px;
`

const AsideTit = styled(AsideTitle)`
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`

const SlideyThing = keyframes`
  from {background-position:-700px}
  to {background-position:700px}
`

const AsideCap = styled(AsideCaption)`
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`
const SingleCardPriceWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  padding: 30% 0px;
  font-size: 32px !important;
`
const SingleCardPrice = styled(AsideTit)`
  padding: 0px;
  font-size: 32px !important;
  font-size: 15px;
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255, 0.5),
    white,
    rgb(255, 255, 255, 0.5)
  );
  color: transparent;
  opacity: 0.9;
  -webkit-background-clip: text;

  animation: ${SlideyThing} 40s infinite linear;
  &:before {
    content: "$";
    font-size: 14px;
  }
`
const Card = styled.div`
  width: 300px;
  height: auto;
  margin: 2em 0em;
  border-radius: 15px;
  padding: 20px;
  background: ${props => (props.background ? props.background : "#283048")};
  color: ${props => (props.background ? "white" : "inherit")};
  transform: ${props => (props.recommended ? "scale(1.07)" : "none")};
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    transform: scale(1.15);
    font-size: 14px;
    z-index: 3;
  }
  ${AsideCap} ${AsideTit} {
    font-size: 15px;
    background: white;
    background: linear-gradient(
      90deg,
      rgb(255, 255, 255, 0.5),
      white,
      rgb(255, 255, 255, 0.5)
    );
    color: transparent;
    opacity: 0.9;
    -webkit-background-clip: text;
    animation: ${SlideyThing} 40s infinite linear;
  }
  ${AsideTit} {
    font-size: 26px;
    background: white;
    background: linear-gradient(
      90deg,
      rgb(255, 255, 255, 0.5),
      white,
      rgb(255, 255, 255, 0.5)
    );
    color: transparent;
    opacity: 0.9;
    -webkit-background-clip: text;
    animation: ${SlideyThing} 10s infinite linear;
  }
  &:hover ${AsideDescription} {
  }
`
const CardV2 = styled.div`
  width: 300px;
  height: auto;
  margin: 2em 0em;
  border-radius: 15px;
  padding: 20px;
  background: ${props => (props.background ? props.background : "#283048")};
  color: ${props => (props.background ? "white" : "inherit")};
  transform: ${props => (props.recommended ? "scale(1.07)" : "none")};
  font-size: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 66%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  padding: 5px;
  font-size: 14px;
  z-index: 3;
`
const CardV2Price = styled.div`
  width: 33%;
  position: relative;
  top: -10%;
  font-size: 50px;
  color: inherit;
  height: 175px;
  border-radius: inherit;
`
const CardV2Content = styled.div`
  width: 33%;
  padding: 0px 1em;
  height: 175px;
  background: rgb(29, 32, 46);
`

const cardBackground = [
  {
    background: "linear-gradient(to right, #30e8bf, #ff8235)",
    color: "white",
    rec_background: "white",
    notRec_background: "red",
    rec_color: "rgb(245, 0, 87)",
    not_rec_color: "white",
  },
  {
    background: "rgb(26,28,40)",
    color: "white",
    rec_background: "rgb(245, 0, 87)",
    notRec_background: "red",
    rec_color: "white",
    not_rec_color: "white",
  },
  {
    background: "linear-gradient(to left, #f12711, #f5af19)",
    color: "white",
    rec_background: "white",
    notRec_background: "red",
    rec_color: "rgb(245, 0, 87)",
    not_rec_color: "white",
  },
]
const PriceLabel = styled.div`
  width: 85%;
  margin: 0 auto;
  text-align: left;
  font-size: 16px;
  height: 10%;
  position: relative;
  top: 22%;
  color: rgb(255, 255, 255, 0.9);
`
const SingleCard = ({ data, noSchedule, btnCallback }) => {
  let { price, title, description } = data
  return (
    <CardV2 style={cardBackground[1]}>
      <CardV2Content>
        <h2>{title}</h2>
        <AsideDescription style={{ color: "white", height: "40%" }}>
          {description || "For a two month course."}
        </AsideDescription>
        <Button
          round
          style={{ marginLeft: "0", position: "relative", left: "-15px" }}
          onClick={() => {
            btnCallback()
          }}
        >
          Choose a class
        </Button>
      </CardV2Content>
      <CardV2Price>
        <PriceLabel last>Twice-Monthly</PriceLabel>
        <SingleCardPriceWrap>
          <SingleCardPrice>{price.instalments || "500"}</SingleCardPrice>
        </SingleCardPriceWrap>
      </CardV2Price>
      <CardV2Price>
        <PriceLabel>One-Time</PriceLabel>
        <SingleCardPriceWrap
          style={{ borderLeft: "1px solid rgb(255,255,255,0.05)" }}
        >
          <SingleCardPrice>{price.full || "1000"}</SingleCardPrice>
        </SingleCardPriceWrap>
      </CardV2Price>
    </CardV2>
  )
}
const FirstCard = ({ data, index, noSchedule, btnCallback }) => {
  let {
    packageName,
    price,
    duration,
    description,
    offered,
    notOffered,
    onClick,
    isRecommended,
  } = data
  return (
    <Card style={cardBackground[index]} recommended={!!isRecommended}>
      {/* <Tagline>Not Recommended</Tagline> */}
      <TagContainer>
        {isRecommended && isRecommended === true ? (
          <Tagline
            background={cardBackground[index]["rec_background"]}
            color={cardBackground[index]["rec_color"]}
          >
            Instructor's Pick
          </Tagline>
        ) : isRecommended === false ? (
          <Tagline
            background={cardBackground[index]["notRec_background"]}
            color={cardBackground[index]["not_rec_color"]}
          >
            Not Recommended
          </Tagline>
        ) : (
          <Tagline></Tagline>
        )}
      </TagContainer>
      <AsideCap>{packageName || "Low-Key"}</AsideCap>
      <AsideTit>
        ${price || "1000"} <TitleSpan>/ {duration || "2 months"}</TitleSpan>
      </AsideTit>
      <hr />
      <AsideDescription style={{ color: "white" }}>
        {description || "For those preparing part-time."}
      </AsideDescription>
      <div style={{ margin: "1em 0" }}>
        {!offered || typeof offered === "undefined" || offered.length < 1
          ? null
          : offered.map((item, index) => (
              <Options key={`pricingOption__offered__grp1${index}`}>
                <CheckedRight />
                <Descrip>{item}</Descrip>
              </Options>
            ))}
        {notOffered.length < 1
          ? null
          : notOffered.map((item, index) => (
              <Options key={`pricingOption__notOffered__grp1${index}`}>
                <Absent />
                <Descrip>{item}</Descrip>
              </Options>
            ))}
      </div>
      {noSchedule && !!noSchedule ? (
        <Button
          style={{
            borderRadius: "15px",
          }}
          onClick={() => {
            btnCallback()
          }}
        >
          Learn More
        </Button>
      ) : (
        <Button
          onClick={() => {
            btnCallback()
          }}
        >
          Schedule
        </Button>
      )}
    </Card>
  )
}
const SecondCard = ({ offered, notOffered, btnCallback }) => (
  <Card background="rgb(26,28,40)" recommended>
    <TagContainer>
      <Tagline recommended>Instructor's Pick</Tagline>
    </TagContainer>
    <AsideCap>Intense</AsideCap>
    <AsideTit>
      $1500 <TitleSpan darkBackground>/ 2 months</TitleSpan>
    </AsideTit>
    <hr />
    <AsideDescription>For those preparing fulltime.</AsideDescription>

    <div style={{ margin: "1em 0" }}>
      {!offered && typeof offered === "undefined" && offered.length < 1
        ? null
        : offered.map((item, index) => (
            <Options key={`pricingOption__offered__grp1${index}`}>
              <CheckedRight />
              <Descrip>{item}</Descrip>
            </Options>
          ))}

      {notOffered.length < 1
        ? null
        : notOffered.map((item, index) => (
            <Options key={`pricingOption__notOffered__grp1${index}`}>
              <Absent />
              <Descrip>{item}</Descrip>
            </Options>
          ))}
    </div>
    <Button
      onClick={() => {
        btnCallback()
      }}
    >
      Select
    </Button>
  </Card>
)
// // background: -webkit-linear-gradient(to left, #f12711, #f5af19); /*
//       Chrome 10-25, Safari 5.1-6 */ // background: linear-gradient(to left,
//       #f12711, #f5af19);
const ThirdCard = ({ offered, notOffered, btnCallback }) => {
  return (
    <Card
      background="linear-gradient(to left,
       #f12711, #f5af19)"
    >
      {/* <Tagline>Not Recommended</Tagline> */}
      <AsideCap>Holy-Smokes</AsideCap>
      <AsideTit>
        $1000 <TitleSpan>/ 2 months</TitleSpan>
      </AsideTit>
      <hr />
      <AsideDescription>For those devoted to excellence.</AsideDescription>
      <div style={{ margin: "1em 0" }}>
        {!offered && typeof offered === "undefined" && offered.length < 1
          ? null
          : offered.map((item, index) => (
              <Options key={`pricingOption__offered__grp1${index}`}>
                <CheckedRight />
                <Descrip>{item}</Descrip>
              </Options>
            ))}
        {notOffered.length < 1
          ? null
          : notOffered.map((item, index) => (
              <Options key={`pricingOption__notOffered__grp1${index}`}>
                <Absent />
                <Descrip>{item}</Descrip>
              </Options>
            ))}
      </div>
      <Button
        onClick={() => {
          btnCallback()
        }}
      >
        Select
      </Button>
    </Card>
  )
}

const MCATPricing = ({
  location,
  backgroundColor,
  card1,
  card2,
  card3,
  title,
  noSchedule,
}) => {
  const [message, setMessage] = useState("")

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.")
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      )
    }
  }, [])
  const manageCallback = () => {
    let pathname =
      typeof window !== "undefined" ? window.location.pathname : "/"
    // if (typeof window !== "undefined") {
    //   pathname = window.location.pathname
    // } else {
    //   pathname = "/"
    // }
    let pathNameLast = pathname.length - 1
    if (pathname[pathNameLast] !== "/") {
      navigate(`${pathname}/schedule`)
    } else {
      navigate(`${pathname}schedule`)
    }
  }
  let cardArray = [card1, card2, card3]
  return (
    <Section wide backgroundColor={backgroundColor || "inherit"}>
      <SectionTitle
        center="center"
        style={{ paddingBottom: "116px" }}
        children={title || "Study Plans"}
      />
      <p></p>
      <CardContainer>
        {cardArray &&
          cardArray.map((cardItem, index) => {
            if (typeof cardItem !== "undefined") {
              return (
                <FirstCard
                  key={"cardContainer" + index}
                  data={cardItem}
                  index={index}
                  btnCallback={manageCallback}
                  noSchedule={noSchedule}
                />
              )
            } else {
              return null
            }
          })}
      </CardContainer>
    </Section>
  )
}
export const McatPricingV2 = ({
  location,
  backgroundColor,
  data,
  title,
  dest,
}) => {
  const manageCallback = () => {
    let pathname =
      typeof window !== "undefined" ? window.location.pathname : "/"

    let pathNameLast = pathname.length - 1
    if (pathname[pathNameLast] !== "/") {
      navigate(dest)
    } else {
      navigate(dest)
    }
  }
  return (
    <Section wide backgroundColor={backgroundColor || "inherit"}>
      <CardContainer>
        <SingleCard data={data} title={title} btnCallback={manageCallback} />
      </CardContainer>
    </Section>
  )
}

export default MCATPricing
