import React from "react"
import styled from "styled-components"

const Label = styled.label`
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
  &::after {
    content: "";
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
`

const Wrapper = styled.div`
  position: relative;
  transform: ${props => (props.scale ? props.scale : "scale(0.7)")};
`
const CheckboxInp = styled.input`
  visibility: hidden;
  &:checked + ${Label} {
    &::after {
      opacity: 1;
    }
  }
  &:checked + ${Label} {
    // background-color: #66bb6a;
    // border-color: #66bb6a;
    background-color: #00b712;
    background-image: linear-gradient(325deg, #00b712 35%, #5aff15 95%);
  }
`

const CheckMark = () => {
  return (
    <Wrapper>
      <CheckboxInp type="checkbox" id="checkbox" />
      <Label htmlFor="checkbox"></Label>
    </Wrapper>
  )
}

const Checked = styled.div`
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: relative;
  top: 6px;
  width: 28px;
  margin: 0 auto;
  background-color: ${props =>
    typeof props.active == undefined || props.active === false
      ? "transparent"
      : "#00b712"};
  background-image: ${props =>
    typeof props.active == undefined || props.active === false
      ? "transparent"
      : "linear-gradient(325deg, #00b712 35%, #5aff15 95%)"};
  &::after {
    content: "";
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    height: 6px;
    left: 7px;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }
`
export const CheckedRight = ({ active, scale }) => {
  return (
    <div>
      <Wrapper scale={scale}>
        <Checked active={active} />
      </Wrapper>
    </div>
  )
}

const Minus = styled(Checked)`
  border-color: ${props => (!!props.colorless ? "#5f5f5f" : "#f3637d")};
  background-color: ${props => (!!props.colorless ? "#3f3d56" : "#f3637d")};
  background: ${props =>
    !!props.colorless
      ? "#3f3d56"
      : "linear-gradient(to bottom right, #f3637d 20%, #aa0813 80%)"};
  &::after {
    border: none;
    transform: none;
    border-bottom: 2px solid #fff;
    height: 6px;
    left: 8px;
    position: absolute;
    top: 7px;
    width: 12px;
  }
`
export const Absent = ({ colorless }) => {
  return (
    <div>
      <Wrapper>
        <Minus colorless={colorless ? true : false} />
      </Wrapper>
    </div>
  )
}

export default CheckMark
